<template>
    <template v-if="viewer !== undefined">
        <li>
            <nuxt-link
                :to="profileLink()"
                class="hidden md:block"
            >
                <prime-button
                    icon="pi pi-user"
                    text
                    severity="secondary"
                    :label="viewer.firstName"
                    :aria-label="`${viewer.firstName} Profile Link`"
                />
            </nuxt-link>

            <nuxt-link
                :to="profileLink()"
                class="md:hidden"
            >
                <prime-button
                    icon="pi pi-user"
                    text
                    severity="secondary"
                />
            </nuxt-link>
        </li>
        <li class="hidden lg:block">
            <prime-button
                type="button"
                label="Sign Out"
                text
                severity="secondary"
                :loading="isSigningOut"
                @click="signOut"
            />
        </li>
    </template>
    <template v-else>
        <li class="hidden md:block">
            <prime-button
                icon="pi pi-user"
                type="button"
                label="Sign In / Register"
                severity="secondary"
                text
                @click="showLogin"
            />
        </li>

        <li class="md:hidden">
            <nuxt-link
                class="p-4 hover:bg-gray-100"
                :to="facilitySignInLink(activeFacility.slug)"
            >
                <i class="pi pi-user" />
            </nuxt-link>
        </li>
    </template>
</template>

<script setup lang="ts">
import { useRouteLink } from '~/composables/routing'
import PrimeButton from 'primevue/button'
import NuxtLink from '#app/components/nuxt-link'
import {
    useLogoutMutation,
    useSetViewer,
    useShowLoginModal,
    useViewer
} from '~/composables/use-login'
import { useSetCartData } from '~/composables/use-cart'
import { useRouter } from 'vue-router'
import { useActiveFacility } from '~/composables/use-active-facility'
import { useToast } from 'primevue/usetoast'

const viewer = useViewer()
const setViewer = useSetViewer()
const showLogin = useShowLoginModal()
const { mutate, isPending: isSigningOut } = useLogoutMutation()

const setCartData = useSetCartData()
const router = useRouter()
const activeFacility = useActiveFacility()
const toast = useToast()
const { facilityHomeLink, facilitySignInLink, profileLink } = useRouteLink()
function signOut() {
    mutate(undefined, {
        onSuccess: data => {
            if (data.logout === true) {
                setViewer(undefined)
                setCartData(undefined)
                router.push(facilityHomeLink(activeFacility.value.slug))
                toast.add({
                    severity: 'info',
                    summary: 'Sign Out Complete',
                    life: 3000
                })
            } else {
                throw new Error()
            }
        }
    })
}
</script>
